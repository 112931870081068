import { faSliders } from '@fortawesome/pro-regular-svg-icons';
import type { JSX } from 'react';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import type { Campaign, TAttributionModel, TCampaignGroup } from '@feathr/blackbox';
import { Button, Checkbox, Fieldset, Icon, Label, Popover } from '@feathr/components';
import { useAccount } from '@feathr/extender/state';
import { AttributionModel } from '@feathr/report_components';

import { getColumns } from '../CampaignsColumns';
import type { IFilters } from '../CampaignsFilters/CampaignsFilters';
import ExportCampaignsButton from '../ExportCampaignsButton';

import * as styles from './ConfigureColumnsButton.css';

interface ISettings {
  attributionModel: TAttributionModel;
  columnIds: string[];
}

interface IProps {
  campaignGroup: TCampaignGroup;
  filters: IFilters;
  onChangeSettings: (settings: ISettings) => void;
  settings: ISettings;
  items: Campaign[];
}

function ConfigureColumnsButton({
  campaignGroup,
  filters,
  onChangeSettings,
  settings,
  items,
}: IProps): JSX.Element {
  const account = useAccount();
  const { t } = useTranslation();
  const { eventId: projectId } = useParams<{ eventId?: string }>();
  const modalRef = useRef<HTMLDivElement>(null);

  const getColumnSetter = (columnId: string) => {
    return () => {
      const newColumnIds = [...settings.columnIds];
      const index = newColumnIds.indexOf(columnId);
      const columnVisible = index >= 0;
      if (columnVisible) {
        newColumnIds.splice(index, 1);
      } else {
        newColumnIds.push(columnId);
      }
      onChangeSettings({ ...settings, columnIds: newColumnIds });
    };
  };

  const includeProjectColumn = projectId === undefined;

  const columnOptionsMap: Record<TCampaignGroup, string[]> = {
    ads: ['project', 'cpm', 'cpc', 'ctr', 'cpa'],
    all: getColumns(settings.attributionModel, includeProjectColumn, account).map(
      (column) => column.id!,
    ),
    email: [
      'project',
      'stats__email_open_rate',
      'stats__email_click_through_rate',
      'stats__hard_bounce_rate',
      'options',
    ],
    'google-ads': ['project', 'options'],
    monetization: [
      'project',
      'stats__email_open_rate',
      'stats__email_click_through_rate',
      'stats__hard_bounce_rate',
      'options',
    ],
    other: [
      'project',
      'stats__email_open_rate',
      'stats__email_click_through_rate',
      'stats__hard_bounce_rate',
      'options',
    ],
  };

  const columns = getColumns(settings.attributionModel, includeProjectColumn, account).filter(
    ({ id }) => columnOptionsMap[campaignGroup].includes(id!),
  );

  function handleChangeAttributionModel(newAttributionModel: TAttributionModel): void {
    const newColumnIds = settings.columnIds.map((cid) => {
      if (cid.includes('roi')) {
        return `stats__conversions__${newAttributionModel}__roi`;
      } else if (cid.includes('conversions')) {
        return `stats__conversions__${newAttributionModel}__num`;
      }
      return cid;
    });
    onChangeSettings({
      attributionModel: newAttributionModel,
      columnIds: newColumnIds,
    });
  }

  return (
    <Popover refs={[modalRef]} toggleOnClick={false}>
      <Button prefix={<Icon icon={faSliders} />}>Configure columns</Button>
      <div className={styles.content}>
        <section>
          <Label>{t('Columns')}</Label>
          <div className={styles.columns}>
            {columns.map((column) => {
              return (
                <Checkbox
                  id={`checkbox-${column.id}`}
                  key={column.id}
                  label={
                    typeof column.checkboxLabel === 'string'
                      ? column.checkboxLabel
                      : column.checkboxLabel?.(items)
                  }
                  name={column.id}
                  onChange={getColumnSetter(column.id!)}
                  value={settings.columnIds.includes(column.id!)}
                />
              );
            })}
          </div>
        </section>
        <section>
          <AttributionModel
            onChange={handleChangeAttributionModel}
            value={settings.attributionModel}
          />
        </section>
        <Fieldset>
          <ExportCampaignsButton
            isFullWidth={true}
            modalRef={modalRef}
            params={{
              attribution_model: settings.attributionModel,
              columns: settings.columnIds,
              filters,
            }}
            theme={'primary'}
          />
        </Fieldset>
      </div>
    </Popover>
  );
}

export default ConfigureColumnsButton;
