import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { Button, Toolbar } from '@feathr/components';
import Page from '@feathr/extender/App/Page';
import { StoresContext } from '@feathr/extender/state';

import DomainsTable from './DomainsTable';

function DomainsPage(): JSX.Element {
  const { t } = useTranslation();
  const { Domains } = useContext(StoresContext);
  const history = useHistory();

  function handleAdd(): void {
    const domain = Domains.create();
    history.push(domain.getItemUrl('edit'));
  }

  return (
    <Page
      actions={
        <Toolbar>
          <Button onClick={handleAdd} prefix={<FontAwesomeIcon icon={faPlus} />} type={'primary'}>
            {t('Add new domain')}
          </Button>
        </Toolbar>
      }
      description={
        <Trans t={t}>
          <p>
            Set up and manage domains to use for sending emails, and serving landing pages and
            invites campaign collateral. Learn more about{' '}
            <a href={'https://help.feathr.co/hc/en-us/articles/360040220793'} target={'_blank'}>
              content domains and email domains
            </a>
            , and how they can be used.
          </p>
          <p>
            We highly recommend also{' '}
            <a href={'https://dmarc.org'} target={'_blank'}>
              configuring the DMARC for your domain
            </a>
            .
          </p>
        </Trans>
      }
      title={t('Domains')}
    >
      <DomainsTable />
    </Page>
  );
}

export default observer(DomainsPage);
