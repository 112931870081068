import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';

import type { IDailyStats } from '@feathr/blackbox';
import type { IAreaChartProps } from '@feathr/components';

import AudienceAreaChart from '../AudienceAreaChart';

import * as styles from './AudienceAreaChartGroup.css';

export interface IChart extends Omit<IAreaChartProps<IDailyStats>, 'xAxisKey'> {
  /*
   * Most stats will want to check against metadata.date. Only change this if you are certain
   * the stats object contains the desired stat to use as the xAxisKey.
   */
  xAxisKey?: 'metadata.date' | string;
  isEmpty?: boolean;
}

interface IProps extends Pick<IAreaChartProps<IDailyStats>, 'showValue'> {
  charts: IChart[];
  data: IDailyStats[];
  rowSize?: 1 | 2 | 3;
  /*
   * If set to false, removes the top border from the first row.
   */
  separator?: boolean;
  /** Whether or not the group of charts is loading. */
  isLoading?: boolean;
  /** Whether or not to backfill the data with default values */
  backfillData?: boolean;
}

function AudienceAreaChartGroup({
  backfillData = false,
  charts,
  data,
  isLoading = false,
  rowSize = 2,
  separator = true,
  showValue,
}: Readonly<IProps>): JSX.Element {
  const rows: IChart[][] = [];

  // Generate rows of charts based on the given rowSize.
  for (let i = 0; i < charts.length; i += rowSize) {
    const row = charts.slice(i, i + rowSize);
    // Add fake elements if the row is not full to maintain sizing.
    while (row.length < rowSize) {
      row.push({} as IChart);
    }
    rows.push(row);
  }

  return (
    <div className={styles.root}>
      {rows.map((row, rowIndex) => (
        <div
          className={classNames(styles.row, styles[rowSize], {
            [styles.noSeparator]: rowIndex === 0 && !separator,
          })}
          key={rowIndex}
        >
          {row.map((chart, chartIndex) => (
            <div
              className={classNames(styles.chart, {
                [styles.middle]: rowSize === 3 && chartIndex === 1,
                [styles.left]: rowSize === 2 && chartIndex === 0,
              })}
              key={chart.yAxisKey}
            >
              {Object.keys(chart).length > 0 ? (
                <AudienceAreaChart
                  {...chart}
                  backfillData={backfillData}
                  className={'chartGroupMember'}
                  data={data}
                  isLoading={isLoading}
                  showValue={showValue}
                  xAxisKey={chart.xAxisKey ?? 'metadata.date'}
                />
              ) : null}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}

export default observer(AudienceAreaChartGroup);
