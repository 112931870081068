import type { TFunction, WithT } from 'i18next';

import { ETheme } from '@feathr/components';
import type { IChart } from '@feathr/extender/components/AudienceAreaChart';

import type { TView } from '../AudienceEngagement';

interface IGoalLabels {
  goalLabelA?: string;
  goalLabelB?: string;
  goalLabelC?: string;
}
interface IChartsProps extends WithT {
  view: TView;
  /** Aggregated value of conversions for a time frame. */
  conversions?: string;
  conversionLabels?: IGoalLabels;
  isEmpty?: boolean;
}

const { green, purple, cyan } = ETheme;

function getEmptyStateDescription(goalCount: number, t: TFunction): string | undefined {
  if (goalCount === 0) {
    return t('No goals contributed conversions during the selected date range.');
  }
  if (goalCount > 0 && goalCount < 3) {
    return t('Only {{count}} goal contributed conversions during the selected date range.', {
      count: goalCount,
    });
  }
  return undefined;
}

export const primaryChart = ({ view, t, conversions }: IChartsProps): IChart | null => {
  const map: Record<TView, IChart | null> = {
    community: {
      label: t('People added'),
      tooltip: t(
        'The total number of people who have been added to your account through any source.',
      ),
      theme: purple,
      yAxisKey: 'num_persons_created',
    },
    conversions: {
      label: t('Total conversions'),
      tooltip: t('The total number of conversions that have been tracked in your account.'),
      theme: green,
      yAxisKey: 'num_conversions',
      descriptor: t('conversions'),
      title: conversions,
    },
    /* We do not render a primary chart for the "Connections" view. */
    connections: null,
  };

  return map[view];
};

export const secondaryCharts = ({ view, t, conversionLabels }: IChartsProps): IChart[] => {
  const { goalLabelA, goalLabelB, goalLabelC } = conversionLabels ?? {};
  const goalCount = [goalLabelA, goalLabelB, goalLabelC].filter(
    (goalLabel) => goalLabel !== undefined,
  ).length;

  const sharedProps = {
    emptyStateLabel: t('No data available'),
    emptyStateDescription: getEmptyStateDescription(goalCount, t),
    descriptor: 'conversions',
  };

  const map: Record<TView, IChart[]> = {
    community: [
      {
        label: t('By audience expansion campaigns'),
        tooltip: t(
          'The number of people who have been added to your account through audience expansion campaigns.',
        ),
        theme: purple,
        yAxisKey: 'num_persons_created_by_expansion',
      },
      {
        label: t('By imports and integrations'),
        tooltip: t(
          'The number of people who have been added to your account through imports and data integrations.',
        ),
        theme: purple,
        yAxisKey: 'num_persons_created_by_import',
      },
      {
        label: t('By pixel'),
        tooltip: t(
          'The number of people who have been added to your account through our tracking pixel.',
        ),
        theme: purple,
        yAxisKey: 'num_persons_created_by_pixel',
      },
    ],
    connections: [
      {
        label: t('Ad clicks'),
        tooltip: t('The number of people in your audience who have clicked ads from ad campaigns.'),
        theme: cyan,
        yAxisKey: 'num_persons_with_ad_clicks',
      },
      {
        label: t('Ad views'),
        tooltip: t(
          'The number of times people in your audience have viewed ads from ad campaigns.',
        ),
        theme: cyan,
        yAxisKey: 'num_persons_with_ad_views',
      },
      {
        label: t('Email opens'),
        tooltip: t('The number people in your audience who have opened your emails.'),
        theme: cyan,
        yAxisKey: 'num_email_opens',
      },
      {
        label: t('Email clicks'),
        tooltip: t(
          'The number of people in your audience who have clicked on links in your emails.',
        ),
        theme: cyan,
        yAxisKey: 'num_email_clicks',
      },
      {
        label: t('Page views'),
        tooltip: t('The number of people in your audience who have visited tracked pages.'),
        theme: cyan,
        yAxisKey: 'num_persons_with_page_views',
      },
      {
        label: t('Page link clicks'),
        tooltip: t(
          'The number of people in your audience who have clicked on links on tracked pages.',
        ),
        theme: cyan,
        yAxisKey: 'num_persons_with_page_link_clicks',
      },
    ],
    conversions: [
      {
        label: goalLabelA ?? t('N/A'),
        tooltip: t(
          'The total number of conversions that have been tracked for your most frequently tracked goal.',
        ),
        theme: green,
        yAxisKey: 'num_conversions_goal_a',
        isEmpty: goalLabelA === undefined,
        // If the goal label is undefined, (because there is no top goal), we want to display a dash instead of the title.
        ...(goalLabelA === undefined && { title: '-', formatTitle: false }),
        ...sharedProps,
      },
      {
        label: goalLabelB ?? t('N/A'),
        tooltip: t(
          'The total number of conversions that have been tracked for your second most frequently tracked goal.',
        ),
        theme: green,
        yAxisKey: 'num_conversions_goal_b',
        isEmpty: goalLabelB === undefined,
        // If the goal label is undefined, (because there is no top goal), we want to display a dash instead of the title.
        ...(goalLabelB === undefined && { title: '-', formatTitle: false }),
        ...sharedProps,
      },
      {
        label: goalLabelC ?? t('N/A'),
        tooltip: t(
          'The total number of conversions that have been tracked for your third most frequently tracked goal.',
        ),
        theme: green,
        yAxisKey: 'num_conversions_goal_c',
        isEmpty: goalLabelC === undefined,
        // If the goal label is undefined, (because there is no top goal), we want to display a dash instead of the title.
        ...(goalLabelC === undefined && { title: '-', formatTitle: false }),
        ...sharedProps,
      },
    ],
  };

  return map[view];
};
