import { faEye } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { PinpointEmailBaseCampaign } from '@feathr/blackbox';
import { Button, CardContent, CardV2 as Card, EmailPreview, Label, Time } from '@feathr/components';
import { EMAIL_TEMPLATE_PREVIEW_RELEASE_DATE } from '@feathr/extender/global';
import { useUser } from '@feathr/extender/state';
import { moment, TimeFormat } from '@feathr/hooks';

import * as styles from './PinpointCampaignDetailSection.css';

interface IProps {
  readonly campaign: PinpointEmailBaseCampaign;
}

function PinpointCampaignDetailSection({ campaign }: IProps): JSX.Element | null {
  const { t } = useTranslation();
  const user = useUser();

  // Prevent rendering if the campaign type is not supported. This should never happen.
  if (!campaign.isEmail) {
    return null;
  }

  const dateCreated = campaign.get('date_created');
  const dateSendStart = campaign.get('date_send_start');
  const fromAddress = campaign.get('from_address');
  const fromName = campaign.get('from_name', t('N/A'));
  const previewUrl = campaign.getTemplatePreviewUrl();
  const subject = campaign.get('subject');
  const previewTitle = t('{{title}} Preview', { title: campaign.get('subject') });
  const userTimezone = user.get('timezone');

  const shouldShowEmailPreview = moment
    .utc(dateCreated)
    .isAfter(moment.utc(EMAIL_TEMPLATE_PREVIEW_RELEASE_DATE));

  return (
    <Card width={'full'}>
      <CardContent contentClassName={styles.content}>
        {shouldShowEmailPreview && <EmailPreview title={previewTitle} url={previewUrl} />}

        <div className={styles.details}>
          <span className={styles.detail}>
            <Label className={styles.label}>{t('Subject')}</Label>
            {subject}
          </span>

          <span className={styles.detail}>
            <Label className={styles.label}>{t('From name')}</Label>
            {fromName}
          </span>

          <span className={styles.detail}>
            <Label className={styles.label}>{t('Sent date')}</Label>
            <Time
              format={TimeFormat.pickerDateTimeZone}
              timestamp={dateSendStart}
              timezone={userTimezone}
            />
          </span>

          <span className={styles.detail}>
            <Label className={styles.label}>{t('From address')}</Label>
            {fromAddress}
          </span>

          {shouldShowEmailPreview && (
            <Button
              className={styles.button}
              href={previewUrl}
              prefix={<FontAwesomeIcon icon={faEye} />}
              target={'_blank'}
              type={'secondary'}
            >
              {t('View email')}
            </Button>
          )}
        </div>
      </CardContent>
    </Card>
  );
}

export default PinpointCampaignDetailSection;
