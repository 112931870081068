import type { JSX } from 'react';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Menu, MenuDivider, MenuItem } from '@feathr/components';
import { useAccount } from '@feathr/extender/state';
import { logUserEvents } from '@feathr/hooks';

import AppcuesMenuItem from './AppcuesMenuItem';

import styles from './HelpNav.css';

interface IAppcuesContent {
  attributes: {
    id: string;
    name: string;
  };
}

interface ILaunchpadResponse extends Record<string, unknown> {
  contents: IAppcuesContent[];
}

interface IAppcuesError {
  error: boolean;
  status: number;
  title: string;
  detail: string;
}

function HelpNav(): JSX.Element {
  const { t } = useTranslation();
  const account = useAccount();
  const [flows, setFlows] = useState<null | IAppcuesContent[]>(null);

  useEffect(() => {
    const url = `https://api.appcues.com/v1/accounts/36596/users/643ee242dbfb63356999aeba/launchpad?url=${window.location}`;

    const getData = async (): Promise<void> => {
      const response = await fetch(url);
      if (!response.ok) {
        const jsonError = (await response.json()) as IAppcuesError;
        throw new Error(`${jsonError.title}: ${jsonError.detail}`);
      }
      const json = (await response.json()) as ILaunchpadResponse;
      setFlows(json.contents);
    };

    getData();
  }, []);

  const logHelpDeskLinkClick = useCallback(() => {
    logUserEvents({ 'Clicked Help Desk link': { account_id: account.id } });
  }, [account]);

  const logFeathrAcademyLinkClick = useCallback(() => {
    logUserEvents({ 'Clicked Feathr Academy link': { account_id: account.id } });
  }, [account]);

  return (
    <Menu className={styles.root}>
      <MenuItem
        data-canny-link
        href={'https://feathr.canny.io/feature-requests'}
        key={'canny'}
        target={'_blank'}
      >
        {t('Feature requests')}
      </MenuItem>
      {flows?.length ? (
        <>
          {flows.map(({ attributes: { id, name } }) => {
            return <AppcuesMenuItem id={id} key={`appcues-${id}`} name={name} />;
          })}
          <MenuDivider key={'divider'} />
        </>
      ) : null}
      <MenuItem
        href={'https://help.feathr.co'}
        key={'help-desk'}
        onClick={logHelpDeskLinkClick}
        target={'_blank'}
      >
        {t('Help desk')}
      </MenuItem>
      <MenuItem
        href={'https://training.feathr.co'}
        key={'training'}
        onClick={logFeathrAcademyLinkClick}
        target={'_blank'}
      >
        {t('Feathr Academy')}
      </MenuItem>
    </Menu>
  );
}

export default HelpNav;
