import type { JSX } from 'react';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import type { DripCampaign } from '@feathr/blackbox';
import type { IWizardStepsCompleted } from '@feathr/components';
import { Button, Step, Steps, useWizardState, Wizard } from '@feathr/components';
import { useActionBar } from '@feathr/extender/state';

import BuilderStep from './BuilderStep';
import DetailsStep from './DetailsStep';
import ExclusionsStep from './ExclusionsStep';
import GoalsStep from './GoalsStep';

import * as styles from './DripCampaignEdit.css';

interface IDripCampaignEditProps {
  campaign: DripCampaign;
}

function DripCampaignEdit({ campaign }: Readonly<IDripCampaignEditProps>): JSX.Element {
  const { setLeftActions, setRightActions } = useActionBar();
  const { t } = useTranslation();

  // TODO: Remove console log once campaign is actually being used
  // eslint-disable-next-line no-console
  console.log('campaign', campaign);

  const getStepErrors = useCallback(
    (step: number): string[] => {
      // If we're loading the wizard, return no errors
      if (step === -1) {
        return [];
      }
      const map = {
        0: ['error to force it to be on first step'],
        1: [],
        2: [],
        3: [],
      };
      return map[step];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const getCompletedStepMap = (): IWizardStepsCompleted => {
    return {
      0: !getStepErrors(0).length,
      1: !getStepErrors(1).length,
      2: !getStepErrors(2).length,
      3: !getStepErrors(3).length,
    };
  };

  // Allows the wizard to know when it is ready to move to the next step
  const waitFor: () => boolean = () => {
    // Should wait for necessary models to be loaded - just a shell for now
    return true;
  };

  const { currentStep, lastStep, onNext, onPrev, onChangeStep } = useWizardState({
    getCompletedStepMap,
    waitFor,
  });

  const steps = (
    <Steps completed={0} current={currentStep} onChange={onChangeStep}>
      <Step key={1} stepIndex={0} title={t('Builder')} />
      <Step key={2} stepIndex={1} title={t('Exclusions')} />
      <Step key={3} stepIndex={2} title={t('Goals')} />
      <Step key={4} stepIndex={3} title={t('Details')} />
    </Steps>
  );

  useEffect(() => {
    setLeftActions(
      // TODO: Replace with SaveCampaign button
      <Button key={'save'} name={'save_changes'}>
        {t('Save as draft')}
      </Button>,
    );
    setRightActions(
      <>
        {currentStep > 0 && <Button onClick={onPrev}>{t('Previous')}</Button>}

        {currentStep === 3 && (
          <Button disabled={true} name={'publish'}>
            {t('Publish')}
          </Button>
        )}
        {currentStep !== lastStep && (
          <Button name={'next_step'} onClick={onNext}>
            {t('Next')}
          </Button>
        )}
      </>,
    );
  }, [currentStep]);

  return (
    <Wizard className={styles.wizard} isFullWidth={true} layout={'horizontal'} steps={steps}>
      <section className={styles.main}>
        {currentStep === 0 && <BuilderStep />}
        {currentStep === 1 && <ExclusionsStep />}
        {currentStep === 2 && <GoalsStep />}
        {currentStep === 3 && <DetailsStep />}
      </section>
    </Wizard>
  );
}

export default DripCampaignEdit;
