import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { ToastType } from 'react-toastify';

import type { Billable, ILicense } from '@feathr/blackbox';
import { Button, Form, SaveButtonValid, toast } from '@feathr/components';
import { useAccount, useActionBar, useLocalUrl } from '@feathr/extender/state';
import { flattenErrors } from '@feathr/hooks';
import type { TValidateGrouped } from '@feathr/rachis';

import BillingAddressCard from './BillingAddressCard';
import BillingConfigurationUsageCard from './BillingConfigurationUsageCard';
import BillingInfoCard from './BillingInfoCard';
import PaymentMethodCard from './PaymentMethodCard';

interface IProps {
  billable: Billable;
  isDefault?: boolean;
  /** Accommodate legacy page component */
  onRedirect?: () => void;
}

function validate(billable: Billable): TValidateGrouped {
  return billable.validate(
    [
      'name',
      'email',
      'address.line1',
      'address.city',
      'address.state',
      'address.postal_code',
      'address.country',
      // TODO: Enable validation of the following fields in a future billing PR
      /*
       * 'stripe.source',
       * 'stripe.source.status',
       */
    ],
    false,
    'grouped',
  ).errors;
}

export function getLabel(isEphemeral: boolean, isDefault: boolean): string {
  // Add primary billing
  if (isEphemeral && isDefault) {
    return t('Add Primary Billing');
  }
  // Add additional billing
  if (isEphemeral) {
    return t('Add Additional Billing');
  }
  // Update primary billing
  if (isDefault) {
    return t('Update Primary Billing');
  }
  // Update additional billing
  return t('Update Additional Billing');
}

function BillableForm({ billable, isDefault, onRedirect }: Readonly<IProps>): JSX.Element {
  const { setRightActions } = useActionBar();
  const account = useAccount();
  const history = useHistory();
  const localUrl = useLocalUrl();
  const [isPrimary, setPrimary] = useState<boolean>(billable.id === account.primaryBillableId);

  useEffect(() => {
    setPrimary(billable.id === account.primaryBillableId);
  }, [account.primaryBillableId, billable.id]);

  // Get data used in validation
  const name = billable.get('name');
  const email = billable.get('email');
  const description = billable.get('description');
  const address = JSON.stringify(billable.get('address'));
  const stripe = JSON.stringify(billable.get('stripe'));

  useEffect(() => {
    async function handleSave(): Promise<void> {
      // Save account.license.billing here ???

      // Only patch if there are changes to primary billing
      if (isPrimary && account.primaryBillableId !== billable.id) {
        try {
          // In some cases an error will be thrown, in others account.error will be set
          const patchedAccount = await account.patch({
            /*
             * The account endpoint handles license in a special way where it
             * will only update keys that are passed in. We are casting to
             * ILicense to make typescript happy and not break account.license
             * for reading purposes.
             */
            license: { billable: billable.id } as ILicense,
          });
          if (patchedAccount.isErrored) {
            throw patchedAccount.error?.errors[0];
          }
        } catch (error) {
          const message = error instanceof Error ? error.message : String(error);
          toast(message, { type: ToastType.ERROR });
        }
      }
      // We must not unset account.license.billing directly

      handleRedirect();
    }

    function handleRedirect(): void {
      if (onRedirect) {
        onRedirect();
      } else {
        const search = new URLSearchParams(window.location.search);
        const redirect = search.get('redirect');
        history.push(redirect ?? localUrl('/settings/billing/configurations'));
      }
    }

    const validationErrors = validate(billable);

    setRightActions(
      <>
        <Button onClick={handleRedirect}>{t('Cancel')}</Button>
        <SaveButtonValid<Billable>
          errors={flattenErrors(validationErrors)}
          key={'save'}
          method={billable.isEphemeral ? 'add' : 'patch'}
          model={billable}
          onSave={handleSave}
          successMessage={t('Billing information saved.')}
          tooltipPlacement={'topRight'}
        >
          {t('Save')}
        </SaveButtonValid>
      </>,
    );
  }, [
    account,
    address,
    billable,
    billable.isEphemeral,
    description,
    email,
    history,
    isPrimary,
    localUrl,
    name,
    onRedirect,
    setRightActions,
    stripe,
  ]);

  return (
    <Form
      data-id={billable.id}
      data-model={'Billable'}
      label={getLabel(billable.isEphemeral, !!isDefault)}
    >
      <BillingInfoCard billable={billable} />
      <BillingAddressCard billable={billable} />
      <PaymentMethodCard billable={billable} />
      <BillingConfigurationUsageCard
        billable={billable}
        isPrimary={isPrimary}
        setPrimary={setPrimary}
      />
    </Form>
  );
}

export default observer(BillableForm);
