import type { JSX } from 'react';
import React from 'react';

import { CardV2 as Card, Layout } from '@feathr/components';

function GoalsStep(): JSX.Element {
  return (
    <Layout>
      <Card>
        <Card.Header title={'Goals Step'} />
      </Card>
    </Layout>
  );
}

export default GoalsStep;
