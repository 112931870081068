import { faFileImport, faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { IObservableArray } from 'mobx';
import { Observer, observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { ICampaignSegment, Importer, PinpointEmailBaseCampaign } from '@feathr/blackbox';
import { CampaignClass } from '@feathr/blackbox';
import {
  Button,
  CardActions,
  CardContent,
  CardHeader,
  CardV2,
  Checkbox,
  Fieldset,
  toast,
} from '@feathr/components';
import ConfirmButton from '@feathr/extender/components/ConfirmButton';
import type { ISegmentSelectChangeProps } from '@feathr/extender/components/SegmentSelect';
import SegmentSelect from '@feathr/extender/components/SegmentSelect';
import { StoresContext, useFlags, useLocalUrl, useUser } from '@feathr/extender/state';
import { moment, TimeFormat } from '@feathr/hooks';

import * as styles from './PinpointEmailCampaignSegments.css';

interface IProps {
  campaign: PinpointEmailBaseCampaign;
  disabled: boolean;
  step?: number;
}

function PinpointEmailCampaignSegments({ campaign, disabled, step }: IProps): JSX.Element {
  const { Goals, Importers, Targetings } = useContext(StoresContext);
  const importerRef = useRef<Importer | undefined>();
  const [segments, setSegments] = useState<IObservableArray<ICampaignSegment>>(
    campaign.get('segments', []),
  );
  const localUrl = useLocalUrl();
  const flags = useFlags();
  const user = useUser();
  const { t } = useTranslation();

  const isAutoSend = campaign.get('_cls') === CampaignClass.AutoPinpointEmail;
  const filteredSegments = !isAutoSend ? segments : segments.filter((s) => !s.included);
  const shouldShowAdditional = isAutoSend && segments.filter((s) => !s.included).length >= 1;

  async function addImporter(): Promise<void> {
    importerRef.current = undefined;
    try {
      const importerAttrs: Record<string, string> = {
        created_by: user.id,
        kind: 'person',
        state: 'draft',
        // TODO: Translate Importer name.
        name: `Import ${moment().format(TimeFormat.isoDate)}`,
      };

      const newImporter = Importers.create(importerAttrs);
      const response = await Importers.add(newImporter, { validate: false });

      // Add group to campaign.
      segments.push({ id: response.get('segment'), included: isAutoSend ? false : true });
      campaign.set({ segments: segments });
      campaign.setAttributeDirty('segments');
      importerRef.current = response;
      // Campaign is saved by <ConfirmButton />.
    } catch (e) {
      toast(t('There was an error creating the import.'), { type: 'error' });
    }
  }

  function getRedirect(): string | false {
    if (!importerRef.current) {
      return false;
    }
    return localUrl(importerRef.current.getItemUrl());
  }

  function handleChangeSegment({ included, oldId, id }: ISegmentSelectChangeProps): void {
    const i = segments.findIndex((s) => s.id === oldId);
    if (i === -1) {
      // This should not happen.
      return;
    }
    segments[i].id = id;
    segments[i].included = included;

    campaign.set({ segments: segments });
    campaign.setAttributeDirty('segments');

    setSegments(campaign.get('segments', []));
  }

  function handleRemoveSegment(id?: string): void {
    if (!id) {
      const segment = segments.find((s) => !s.id);
      if (segment) {
        segments.remove(segment);
      }
    } else {
      campaign.set({
        segments: campaign.get('segments', []).filter((s) => s.id !== id),
      });
      setSegments(campaign.get('segments', []));
    }
    if (!campaign.get('segments', []).length) {
      campaign.set({ consent: { has_consent: false } });
    }
  }

  function addSegment(): void {
    segments.push({ id: '', included: isAutoSend ? false : true });
  }

  function handleChangeEndpointType(checked?: boolean): void {
    campaign.set({ endpoint_type: checked ? 'all_emails' : 'primary_email' });
  }

  return (
    <>
      <CardV2 width={'full'}>
        <CardHeader title={t('Groups')}>
          <ConfirmButton
            description={t(
              'Before you can import contacts, the changes to your campaign need to be saved.',
            )}
            disabled={disabled}
            model={campaign}
            name={'import_contacts'}
            onConfirm={addImporter}
            prefix={<FontAwesomeIcon icon={faFileImport} />}
            redirect={getRedirect}
            step={step}
          >
            {t('Import contacts')}
          </ConfirmButton>
        </CardHeader>

        <CardContent>
          <>
            {segments.length === 0 && <div className={styles.empty}>{t('No groups selected')}</div>}
            {!!segments.length && (
              <Fieldset className={styles.segment}>
                {filteredSegments.map((segment) => (
                  <Observer key={segment.id || 'add'}>
                    {(): JSX.Element => {
                      const affectedTargetings = Targetings.list({
                        filters: {
                          _parent__ne: campaign.id,
                          _target_data: segment.id || undefined,
                        },
                        pagination: {
                          distinct: 'parent',
                        },
                      });
                      const affectedGoals = Goals.list({
                        filters: { segment: segment.id || undefined },
                      });
                      return (
                        <SegmentSelect
                          affectedCampaigns={affectedTargetings.pagination.distinct}
                          affectedGoals={affectedGoals.pagination.count}
                          disabled={disabled}
                          disableInclusion={isAutoSend}
                          excludeIds={segments.filter((s) => s.id !== undefined).map((s) => s.id!)}
                          included={segment.included}
                          name={'segment_select'}
                          onChange={handleChangeSegment}
                          onRemove={handleRemoveSegment}
                          stat={'num_emails_total'}
                          value={segment.id}
                        />
                      );
                    }}
                  </Observer>
                ))}
              </Fieldset>
            )}
          </>
        </CardContent>
        <CardActions>
          {flags.pinpointSendAllEmails && (
            <Checkbox
              className={styles.endpointType}
              label={t(
                "Some people have multiple email addresses stored on their profile. Send to all email addresses for every person in the campaign's targets.",
              )}
              onChange={handleChangeEndpointType}
              value={campaign.get('endpoint_type') === 'all_emails'}
            />
          )}
          <Button
            disabled={disabled || segments.some((s) => !s.id)}
            name={'add_segment'}
            onClick={addSegment}
            prefix={<FontAwesomeIcon icon={faPlus} />}
            type={'primary'}
          >
            {(isAutoSend ? shouldShowAdditional : segments.length >= 1)
              ? t('Additional group')
              : t('Group')}
          </Button>
        </CardActions>
      </CardV2>
    </>
  );
}

export default observer(PinpointEmailCampaignSegments);
