import type { TFunction } from 'i18next';
import { when } from 'mobx';

import type { IPredicate, TComparison, TPredicateKind } from '@feathr/blackbox';
import { FieldCollection, FieldDataType, Segment } from '@feathr/blackbox';
import type { ISelectOption } from '@feathr/components';

import defaultBreadcrumbFields from '@feathr/blackbox/fixtures/defaultBreadcrumbFields.json';
import defaultPersonFields from '@feathr/blackbox/fixtures/defaultPersonFields.json';

const { bool, date, float, int, list, str } = FieldDataType;

export type TOptionsGroup =
  | 'campaign'
  | 'flight'
  | 'segment'
  | 'campaignGoal'
  | 'flightGoal'
  | 'facebookCampaign'
  | 'pinpoint';

export interface IAttrOption extends ISelectOption {
  helpText: string;
  kind: FieldCollection.Breadcrumb | FieldCollection.Person;
  type: FieldDataType;
}

export interface IComparisonOption {
  id: TComparison;
  name: string;
}

export function getComparisonOptions(
  { id, type }: Pick<IAttrOption, 'id' | 'type'>,
  t: TFunction,
): IComparisonOption[] {
  const {
    array,
    equality,
    existence,
    numerical,
    string,
    temporal,
  }: Record<string, IComparisonOption[]> = {
    equality: [
      { id: 'eq', name: t('is') },
      { id: 'ne', name: t('is not') },
    ],
    existence: [
      { id: 'exists', name: t('exists') },
      { id: 'nexists', name: t('does not exist') },
    ],
    array: [
      { id: 'in', name: t('contains') },
      { id: 'nin', name: t('does not contain') },
    ],
    numerical: [
      { id: 'eq', name: t('equals') },
      { id: 'ne', name: t('does not equal') },
      { id: 'gt', name: t('is greater than') },
      { id: 'lt', name: t('is less than') },
    ],
    string: [
      { id: 'starts_with', name: t('starts with') },
      { id: 'wildcard', name: t('matches pattern') },
      { id: 'regexp', name: t('matches the expression') },
      { id: 'contains_substring', name: t('contains') },
    ],
    temporal: [
      { id: 'gt', name: t('is after') },
      { id: 'lt', name: t('is before') },
    ],
  };

  switch (type) {
    case str: {
      const baseOptions = [...existence, ...equality];
      // Don't allow string comparisons for ObjectId fields.
      if (id.includes('_id')) {
        return baseOptions;
      }
      return [...baseOptions, ...string];
    }

    case int:

    case float:
      return [...existence, ...numerical];

    case bool:
      return equality;

    case date:
      return [...existence, ...equality, ...temporal];

    case list:
      return [...existence, ...array];

    default:
      return existence;
  }
}

export async function getAttributes(t, CustomFields): Promise<IAttrOption[]> {
  const helpText = t('This is a custom field that has been added to your Feathr database.');

  const customAttributes = await CustomFields.list({
    filters: {
      collection: FieldCollection.Person,
    },
    pagination: {
      page: 0,
      page_size: 1000,
    },
  });

  await when(() => !customAttributes.isPending);
  const options = customAttributes.models.map((field) => ({
    helpText: field.get('description', helpText),
    id: `custom.${field.get('f_key')}`,
    kind: field.get('collection'),
    name: field.get('u_key'),
    type: field.get('data_type'),
  }));

  return [...defaultPersonFields, ...options];
}

export async function getActivities({
  CustomFields,
  model,
  isAnyFeathrPlatform,
  optionsGroup,
  t,
}): Promise<IAttrOption[]> {
  const helpText = t('This is a custom field that has been added to your Feathr database.');

  const customActivities = CustomFields.list({
    filters: {
      collection: FieldCollection.Breadcrumb,
    },
    pagination: {
      page: 0,
      page_size: 1000,
    },
  });

  await when(() => !customActivities.isPending);
  const options = customActivities.models.map((field) => ({
    helpText: field.get('description', helpText),
    id: `cust_params.${field.get('f_key')}`,
    kind: field.get('collection'),
    name: field.get('u_key'),
    type: field.get('data_type'),
  }));

  function shouldRender(field: ISelectOption): boolean {
    const render = model instanceof Segment ? model.get('read_only') : false;
    const gated = [
      {
        id: 'p_id',
        render: isAnyFeathrPlatform,
      },
      {
        id: 'q_id',
        render,
      },
      {
        id: 'msg',
        render,
      },
    ];

    const gatedAttribute = gated.find(({ id }) => id === field.id);

    // If it's a gated attribute, we want to check its render conditions.
    if (gatedAttribute) {
      return gatedAttribute.render;
    } else if (optionsGroup === 'pinpoint') {
      /**
       * There are certain activity predicates we don't want used
       * in email campaign groups.
       */
      return !['brow', 'pform', 'd_c', 'isp'].includes(field.id);
    } else {
      return true;
    }
  }

  return [...defaultBreadcrumbFields.filter(shouldRender), ...options];
}

export function toPredicateAttrType(
  type: FieldDataType,
): 'string' | 'boolean' | 'list' | 'date' | 'integer' | 'float' {
  switch (type) {
    case str:
      return 'string';

    case int:
      return 'integer';

    case bool:
      return 'boolean';

    default:
      return type;
  }
}

export function shouldShowValueInput({ comparison }: IPredicate): boolean {
  // Don't show value input for existence comparisons.
  if (!comparison || ['exists', 'nexists'].includes(comparison)) {
    return false;
  }
  return true;
}

export function fieldToKind(
  fieldCollection: FieldCollection.Breadcrumb | FieldCollection.Person | 'Pinpoint',
): TPredicateKind {
  const { Breadcrumb, Person } = FieldCollection;
  const map = {
    [Breadcrumb]: 'activity',
    [Person]: 'attribute',
    Pinpoint: 'update',
  };

  return map[fieldCollection] as TPredicateKind;
}

export interface IRelativeDateOptions {
  daysAgo: ISelectOption;
  daysFromNow: ISelectOption;
}

export function getRelativeDateOptionsMap(t: TFunction, count = 1): IRelativeDateOptions {
  const relativeDateOptions = [
    { id: '-', name: t('day ago', { count }) },
    { id: '+', name: t('day from now', { count }) },
  ];

  return {
    daysAgo: relativeDateOptions[0],
    daysFromNow: relativeDateOptions[1],
  };
}

export interface IDateComparisonOptions {
  today: ISelectOption;
  exactDate: ISelectOption;
  exactDateAndTime: ISelectOption;
  relativeDate: ISelectOption;
}

export function getDateComparisonOptionsMap(t: TFunction): IDateComparisonOptions {
  const dateComparisonOptions = [
    {
      name: t('today'),
      /*
       * Maps to a `_date_r` comparison with a 0 value and does not
       * reference its own unique comparison operator.
       */
      id: 'today',
    },
    {
      name: t('exact date'),
      // Maps to new comparison operators: eq_date, ne_date, gt_date and lt_date.
      id: '_date',
    },
    {
      name: t('exact date and time'),
      // Maps to the existing comparison operators, eq, ne, gt and lt.
      id: 'date_time',
    },
    {
      name: t('relative date'),
      // Maps to new comparison operators: eq_date_r, ne_date_r, gt_date_r and lt_date_r.
      id: '_date_r',
    },
  ];

  return {
    today: dateComparisonOptions[0],
    exactDate: dateComparisonOptions[1],
    exactDateAndTime: dateComparisonOptions[2],
    relativeDate: dateComparisonOptions[3],
  };
}
