import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import type { Person } from '@feathr/blackbox';
import { SaveButtonValid } from '@feathr/components';
import { StoresContext, useActionBar } from '@feathr/extender/state';
import { flattenErrors } from '@feathr/hooks';
import type { TValidateGrouped } from '@feathr/rachis';

import AddressData from './AddressData';
import EmailAddresses from './EmailAddresses';
import GeneralInfo from './GeneralInfo';
import Location from './Location';
import Metadata from './Metadata';
import TrackedLocationData from './TrackedLocationData';

import * as styles from './Profile.css';

interface IProps {
  person: Person;
}

function validatePersonInfo(person: Person): TValidateGrouped {
  return person.validate(['email', 'phone', 'emails'], false, 'grouped').errors;
}

function Profile({ person }: IProps): JSX.Element {
  const { Persons } = useContext(StoresContext);

  const { t } = useTranslation();
  const validationErrors = validatePersonInfo(person);
  const { setRightActions } = useActionBar();

  useEffect(() => {
    setRightActions(
      <SaveButtonValid
        disabled={!person.isDirty || Object.keys(validationErrors).length > 0}
        errors={flattenErrors(validationErrors)}
        method={'patch'}
        model={person}
        tooltipPlacement={'topRight'}
      >
        {t('Save')}
      </SaveButtonValid>,
    );
  }, [person.isDirty, person.get('email'), person.get('emails'), person.get('phone')]);

  /*
   * When we unmount the profile page, let's clear out any dirty content so it's
   * not there when we navigate back because it looks like it has been saved when
   * it has not.
   */
  useEffect(() => {
    return () => {
      Persons.reset();
    };
  }, []);

  return (
    <div className={styles.root}>
      <div>
        <GeneralInfo person={person} />
        <EmailAddresses person={person} validationErrors={validationErrors} />
        <AddressData person={person} />
      </div>
      <div>
        <Location person={person} />
        <TrackedLocationData person={person} />
        <Metadata person={person} />
      </div>
    </div>
  );
}

export default observer(Profile);
