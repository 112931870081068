import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ToastType } from 'react-toastify';

import type { TModalActionEvent } from '@feathr/components';
import { EmailInput, Form, Modal, toast, Tooltip } from '@feathr/components';
import { StoresContext } from '@feathr/extender/state';
import { flattenError, logUserEvents } from '@feathr/hooks';
import { validate } from '@feathr/rachis';

import RoleSelect from '../Roles/RoleSelect';

import * as styles from './InviteUsersModal.css';

interface IBulkAssignModalProps {
  onClose: (event: TModalActionEvent) => void;
}

// TODO: Will be updated in the integration ticket #3248

function InviteUsersModal({ onClose }: Readonly<IBulkAssignModalProps>): JSX.Element {
  const { Users } = useContext(StoresContext);
  const [emails, setEmails] = useState<string | undefined>();
  const [emailList, setEmailList] = useState<string[]>([]);
  const [emailHadFocus, setEmailHadFocus] = useState<boolean>(false);
  const [role, setRole] = useState<string>();

  const { t } = useTranslation();

  useEffect(() => {
    setEmailList(emails?.split(',').map((email) => email.trim()) ?? []);
  }, [emails]);

  function handleClose(event: TModalActionEvent): void {
    setEmails('');
    setEmailHadFocus(false);
    onClose(event);
  }

  async function handleInvite(event: TModalActionEvent): Promise<void> {
    if (role) {
      try {
        // If there are more than 10 users to invite, the invites are processed as an async job.
        if (emailList.length < 10) {
          await Users.invite({ emailList, role });
          toast(t('Sent invite links to {{count}} user', { count: emailList.length }), {
            type: ToastType.SUCCESS,
          });
        } else {
          Users.invite({ emailList, role });
          toast(t('Your invites are being processed. Check back later.'), { type: ToastType.INFO });
        }

        logUserEvents({
          'Invited user': {
            invited_users_emails: [...emailList],
          },
        });

        handleClose(event);
      } catch (error) {
        toast(t('There was an error sending your invites.\n{{- error}}', { error }), {
          type: ToastType.ERROR,
        });
      }
    }
  }

  function handleSelectRole(roleId: string): void {
    setRole(roleId);
  }

  function validateEmail(): string[] {
    const error = validate.single(emailList, {
      presence: { allowEmpty: false, message: '^Email addresses cannot be empty.' },
      list: {
        email: {
          message: '^An email address is invalid.',
        },
      },
    });
    return flattenError(error) ?? [];
  }

  function validateRole(): string[] {
    const error = validate.single(role, {
      presence: { allowEmpty: false, message: '^A role must be selected.' },
    });
    return flattenError(error) ?? [];
  }

  const emailAddressHelpText = (
    <div className={styles.emailHelpText}>
      <span>{t('You must add one or more emails separated by commas')}</span>
    </div>
  );

  const roleHelpText = (
    <Trans t={t}>
      <span>
        Newly invited users will be assigned the selected role.{' '}
        <Tooltip
          title={
            <p>
              <strong>Users</strong> can access any project.
              <br />
              <strong>Admins</strong> can access any project, invite new users, and change user
              roles.
            </p>
          }
        >
          <FontAwesomeIcon icon={faInfoCircle} />
        </Tooltip>
      </span>
    </Trans>
  );

  function handleBlurEmail(): void {
    setEmailHadFocus(true);
  }

  const validationErrors = [...validateEmail(), ...validateRole()];

  return (
    <Modal
      confirmButtonText={t('Invite')}
      confirmDisabled={!!validationErrors.length}
      controlled={true}
      errors={validationErrors}
      onClose={onClose}
      onConfirm={handleInvite}
      size={'sm'}
      t={t}
      title={t('Invite Users')}
      useValidation={true}
    >
      <Form label={t('Invite users')}>
        <EmailInput
          allowMultiple={true}
          helpPlacement={'bottom'}
          helpText={emailAddressHelpText}
          label={t('Email addresses')}
          name={'email_addresses'}
          onBlur={handleBlurEmail}
          onChange={setEmails}
          placeholder={t('Add one or more emails separated by commas')}
          t={t}
          type={'email'}
          validationError={emailHadFocus ? validateEmail() : []}
          value={emails}
        />
        <RoleSelect helpText={roleHelpText} onChange={handleSelectRole} />
      </Form>
    </Modal>
  );
}

export default observer(InviteUsersModal);
