import { action } from 'mobx';

import { concatPath } from '@feathr/hooks';
import type { IAddOptions, TConstraints, TRachisEmpty } from '@feathr/rachis';
import { Collection, isWretchError, wretch } from '@feathr/rachis';

import { FieldDataType } from './custom_fields';
import type { IIntegrationAttributes } from './integrations';
import { BaseIntegration } from './integrations';
import type {
  IIntegrationBaseMapping,
  TIntegrationPersonAttributes,
  TIntegrationSyncRule,
} from './integrations/integrations';
import { ECollectionClassName } from './model';

// The Blackbaud user that is authenticated with the Blackbaud Raiser's Edge NXT product.
export interface IBlackbaudAuthenticatedUser {
  email: string;
  family_name: string;
  given_name: string;
  user_id: string;
}

interface IDownsyncActivities {
  gift_events: boolean;
}

export enum EBlackbaudRaisersEdgeUnsubscribeSyncActions {
  RequestsNoEmail = 'requests_no_email',
  SolicitCode = 'solicit_code',
  DoNotSync = 'do_not_sync',
}

export interface IBlackbaudUnsubscribeSyncPreference {
  action: EBlackbaudRaisersEdgeUnsubscribeSyncActions;
  solicit_code?: string;
}

// Integration for the Blackbaud Raiser's Edge NXT product.
export interface IBlackbaudRaisersEdgeIntegrationAttributes extends IIntegrationAttributes {
  authenticated_user?: IBlackbaudAuthenticatedUser;
  downsync_activities: IDownsyncActivities;
  login_url: string;
  type: 'BlackbaudRaisersEdgeIntegration';
  unsubscribe_sync_preferences: IBlackbaudUnsubscribeSyncPreference;
}

export enum EBlackbaudRaisersEdgeContactMappingKeys {
  // Strings
  age = 'age',
  email = 'email',
  first = 'first',
  former_name = 'former_name',
  fundraiser_status = 'fundraiser_status',
  gender = 'gender',
  id = 'id',
  last = 'last',
  lookup_id = 'lookup_id',
  marital_status = 'marital_status',
  middle = 'middle',
  name = 'name',
  phone = 'phone',
  preferred_name = 'preferred_name',
  primary_addressee = 'primary_addressee',
  primary_salutation = 'primary_salutation',
  suffix = 'suffix',
  suffix_2 = 'suffix_2',
  title = 'title',
  title_2 = 'title_2',
  type = 'type',

  // Dates
  birthdate = 'birthdate',
  date_added = 'date_added',
  date_modified = 'date_modified',
  date_of_first_gift = 'date_of_first_gift',
  date_of_last_gift = 'date_of_last_gift',
  deceased_date = 'deceased_date',

  // Booleans
  address = 'address',
  deceased = 'deceased',
  gives_anonymously = 'gives_anonymously',
  inactive = 'inactive',

  // Numbers
  consecutive_years_given = 'consecutive_years_given',
  first_gift_amount = 'first_gift_amount',
  greatest_gift_amount = 'greatest_gift_amount',
  last_gift_amount = 'last_gift_amount',
  total_giving = 'total_giving',
  total_years_given = 'total_years_given',

  // Lists
  constituent_codes = 'constituent_codes',
  emails = 'emails',
  solicit_codes = 'solicit_codes',
}

export type TBlackbaudContactMappingKey = keyof typeof EBlackbaudRaisersEdgeContactMappingKeys;
export interface IBlackbaudContactMapping extends IIntegrationBaseMapping {
  key: TBlackbaudContactMappingKey;
  integration: IBlackbaudRaisersEdgeIntegrationAttributes['id'];
  default_field: TBlackbaudContactMappingKey | null;
  type: 'BlackbaudRaisersEdgeContactMapping';
}

interface IUpdateContactMapping {
  customField?: string | null;
  defaultField?: TIntegrationPersonAttributes | null;
  syncRule?: TIntegrationSyncRule;
  mapping: IBlackbaudContactMapping['id'];
}

export interface ISolicitCodes extends Record<string, unknown> {
  solicit_codes: string[];
}

export enum EBlackbaudRaisersEdgeActivityMappingKeys {
  gift_events = 'gift_events',
}

export enum EBlackbaudRaisersEdgeActivityDataTypes {
  gift_events = FieldDataType.str,
}

export class BlackbaudRaisersEdgeIntegration extends BaseIntegration<IBlackbaudRaisersEdgeIntegrationAttributes> {
  public readonly className = 'BlackbaudRaisersEdgeIntegration';

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public constraints: TConstraints<any> = {
    'unsubscribe_sync_preference.action': {
      presence: {
        allowEmpty: false,
        message: '^Unsubscribe action must not be blank.',
      },
    },
    'unsubscribe_sync_preference.solicit_code': {
      presenceUnless: {
        allowEmpty: false,
        message: '^Unsubscribe solicit code must not be blank.',
        unless: (attributes) => attributes.unsubscribe_sync_preference.action !== 'solicit_code',
      },
    },
  };

  public getItemUrl(pathSuffix: string | undefined): string {
    return concatPath(`integrations/raisers-edge/${this.id}`, pathSuffix);
  }

  public get name(): string {
    return 'raisers_edge_integration';
  }

  // This url will not be necessary once we create the BlackbaudContactMappings collection class.
  public url(): string {
    return `${this.collection!.url()}${this.id}/contact_mappings`;
  }

  /*
   * Blackbaud Contact Mapping CRUD methods
   * These are a temporary measure. They will not be necessary once the work is done to
   * create an BlackbaudContactMappings collection class in #2651 and #2722.
   */

  // GET
  @action
  public async getContactMappings(): Promise<IBlackbaudContactMapping[]> {
    // TODO: Implement sorting in the query as part of #2991.
    const response = await wretch<IBlackbaudContactMapping[]>(this.url(), {
      headers: this.collection!.getHeaders(),
      method: 'GET',
    });

    if (isWretchError(response)) {
      throw response.error;
    }

    return response.data;
  }

  private async addContactMapping(
    key: TBlackbaudContactMappingKey,
  ): Promise<IBlackbaudContactMapping> {
    this.assertCollection(this.collection, ECollectionClassName.BlackbaudContactMappings);

    const response = await wretch<IBlackbaudContactMapping>(this.url(), {
      headers: this.collection!.getHeaders(),
      method: 'POST',
      body: JSON.stringify({ contact_mapping: key }),
    });

    if (isWretchError(response)) {
      throw response.error;
    }

    return response.data;
  }

  public async getSolicitCodes(): Promise<ISolicitCodes> {
    this.assertCollection(this.collection, ECollectionClassName.BlackbaudRaisersEdgeIntegrations);
    const url = `${this.collection.url()}${this.id}/solicit_codes`;
    const response = await wretch<ISolicitCodes>(url, {
      headers: this.collection.getHeaders(),
      method: 'GET',
    });

    if (isWretchError(response)) {
      throw response.error;
    }

    return response.data;
  }

  // POST
  @action
  public async addContactMappings(
    mappings: TBlackbaudContactMappingKey[],
  ): Promise<IBlackbaudContactMapping[]> {
    const response = Promise.all(mappings.map((mapping) => this.addContactMapping(mapping)));
    return response;
  }

  // PATCH
  @action
  public async updateContactMapping({
    mapping,
    customField,
    defaultField,
    syncRule,
  }: IUpdateContactMapping): Promise<IBlackbaudContactMapping> {
    this.assertCollection(this.collection, ECollectionClassName.BlackbaudContactMappings);

    const url = `${this.url()}/${mapping}`;
    const response = await wretch<IBlackbaudContactMapping>(url, {
      method: 'PATCH',
      headers: this.collection!.getHeaders(),
      body: JSON.stringify({
        // When patching a field you can either set the custom_field or the default_field, but not both.
        custom_field: customField ?? null,
        default_field: defaultField ?? null,
        sync_rule: syncRule,
      }),
    });

    if (isWretchError(response)) {
      throw response.error;
    }

    return response.data;
  }

  // DELETE
  @action
  public async deleteContactMapping(id: string): Promise<void> {
    this.assertCollection(this.collection, ECollectionClassName.BlackbaudContactMappings);

    const url = `${this.url()}/${id}`;
    const response = await wretch<TRachisEmpty>(url, {
      headers: this.collection!.getHeaders(),
      method: 'DELETE',
    });

    if (isWretchError(response)) {
      throw new Error('Failed to delete contact mapping.');
    }
  }
}

export class BlackbaudRaisersEdgeIntegrations extends Collection<BlackbaudRaisersEdgeIntegration> {
  public getModel(
    attributes: IBlackbaudRaisersEdgeIntegrationAttributes,
  ): BlackbaudRaisersEdgeIntegration {
    return new BlackbaudRaisersEdgeIntegration(attributes);
  }

  public getClassName(): string {
    return 'raisers_edge_integration';
  }

  public url(): string {
    return `${this.getHostname()}integrations/raisers-edge/`;
  }

  public async add(
    model: BlackbaudRaisersEdgeIntegration,
    options: Partial<IAddOptions> = {},
  ): Promise<BlackbaudRaisersEdgeIntegration> {
    const { validate = true, refreshApiCache = true } = options;
    if (validate && !model.isValid()) {
      throw new Error('Model is invalid');
    }
    const url = `${BLACKBOX_URL}integrations/raisers-edge/`;

    const response = await wretch<IBlackbaudRaisersEdgeIntegrationAttributes>(url, {
      headers: this.getHeaders(),
      method: 'POST',
    });

    if (isWretchError(response)) {
      this.processErrorResponse(model, response.error);
      return model;
    }

    if (refreshApiCache) {
      this.refreshApiCache();
    }

    return this.processJSONResponse(response);
  }
}
