import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CardV2 as Card, TimeFrameSelect } from '@feathr/components';
import { useTimeFrameSelect } from '@feathr/extender/hooks';
import { ECardCollapseKeys, ENamespaces, ETimeFrameKeys } from '@feathr/hooks';

import EmailPerformanceCharts from './EmailPerformanceCharts';

import * as styles from './EmailPerformance.css';

function EmailPerformance(): JSX.Element {
  const { t } = useTranslation();
  const { options, timeFrame, timeFrameValue, handleChangeTimeFrame } = useTimeFrameSelect({
    persist: {
      key: ETimeFrameKeys.EmailPerformance,
      namespace: ENamespaces.AccountDashboard,
    },
  });

  return (
    <Card width={'full'}>
      <Card.Header
        isCollapsible={true}
        persist={{
          key: ECardCollapseKeys.EmailPerformance,
          namespace: ENamespaces.AccountDashboard,
        }}
        title={t('Email Performance')}
        width={'full'}
      >
        <TimeFrameSelect onChange={handleChangeTimeFrame} options={options} value={timeFrame} />
      </Card.Header>
      <Card.Content contentClassName={styles.content} name={'email-performance-card-content'}>
        <EmailPerformanceCharts timeFrameValue={timeFrameValue} />
      </Card.Content>
    </Card>
  );
}

export default EmailPerformance;
