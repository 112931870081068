import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, ConfirmModal, EmailInput } from '@feathr/components';
import { useToggle } from '@feathr/hooks';
import type { TValidateDetailed } from '@feathr/rachis';

import * as styles from './AdditionalEmail.css';

interface IEmail {
  key: string;
  value: string;
}
interface IEmailInputProps {
  email: IEmail;
  emails: IEmail[];
  index: number;
  handleChangeEmail: (newValue: string, key: string) => void;
  handleRemoveEmail: (index: number) => void;
  validationErrors: TValidateDetailed;
}

function AdditionalEmail({
  email: { key, value },
  index,
  handleChangeEmail,
  handleRemoveEmail,
  validationErrors,
}: Readonly<IEmailInputProps>): JSX.Element {
  const { t } = useTranslation();
  const [isDeleteModalOpen, toggleDeleteModal] = useToggle(false);

  function handleChange(newValue?: string): void {
    handleChangeEmail(newValue ?? '', key);
  }

  function handleRemove(): void {
    handleRemoveEmail(index);
  }

  const duplicates = validationErrors.find(({ validator }) => validator === 'duplicates');
  const additionalEmails = ((duplicates?.value || []) as string[]).map((email: string) =>
    email.toLowerCase(),
  );

  // Show duplicates error if the email is both 1) a duplicate, and 2) the last duplicate in the list.
  const isDuplicate = additionalEmails.filter((email) => email === value.toLowerCase()).length > 1;
  const isLastDuplicate =
    isDuplicate && additionalEmails.lastIndexOf(value.toLowerCase()) === index;
  const lastDuplicateError = isDuplicate && isLastDuplicate ? duplicates?.error : undefined;

  const validation = validationErrors[index];
  // Don't show duplicates error for unique emails.
  const validationError =
    !isDuplicate && validation?.validator !== 'duplicates' ? validation?.error : undefined;

  return (
    <div>
      <EmailInput
        isClearable={true}
        name={'additional-email-input'}
        onChange={handleChange}
        t={t}
        validationError={value.length > 0 ? lastDuplicateError || validationError : undefined}
        value={value}
        wrapperClassName={styles.container}
      />
      <Button
        className={styles.button}
        onClick={value.length > 0 ? toggleDeleteModal : handleRemove}
        prefix={<FontAwesomeIcon icon={faTrash} />}
        type={'naked'}
      />
      {isDeleteModalOpen && (
        <ConfirmModal
          confirmButtonText={t('Remove email')}
          onClose={toggleDeleteModal}
          onConfirm={handleRemove}
          t={t}
          title={t('Are you sure you want to remove this email?')}
        />
      )}
    </div>
  );
}

export default AdditionalEmail;
