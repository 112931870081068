import { faArrowUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastType } from 'react-toastify';

import type {
  BlackbaudRaisersEdgeIntegration,
  IBlackbaudRaisersEdgeIntegrationAttributes,
} from '@feathr/blackbox';
import { Button, CardV2 as Card, Step, toast, Toolbar } from '@feathr/components';
import { StoresContext, useAccount } from '@feathr/extender/state';

import * as styles from './RaisersEdgeConnectBlackbaudCard.css';

type TBlackbaudLoginUrl = IBlackbaudRaisersEdgeIntegrationAttributes['login_url'] | undefined;

interface IProps {
  integration?: BlackbaudRaisersEdgeIntegration;
}

function RaisersEdgeConnectBlackbaudCard({ integration }: IProps): JSX.Element {
  const account = useAccount();
  const { t } = useTranslation();
  const { BlackbaudRaisersEdgeIntegrations } = useContext(StoresContext);
  const loginUrl: TBlackbaudLoginUrl = integration?.get('login_url');
  const [confirmed, setConfirmed] = useState(
    !!account.getSetting('confirmed_bbrenxt_marketplace_sync'),
  );

  async function handleClickConnect(): Promise<void> {
    if (!integration) {
      try {
        const newIntegration = BlackbaudRaisersEdgeIntegrations.create();
        // Setting validate to false because we don't want to validate the integration until the end.
        const returnedIntegration = await BlackbaudRaisersEdgeIntegrations.add(newIntegration, {
          validate: false,
        });
        // Used when we add a new model, so that it would force refresh collection.list() that could include that new model, if it had previously existed.
        BlackbaudRaisersEdgeIntegrations.clearApiCache();
        // Use the login_url from the new integration to redirect the user to the Blackbaud login page.
        const newLoginUrl: TBlackbaudLoginUrl = returnedIntegration.get('login_url');

        if (newLoginUrl) {
          window.location.assign(newLoginUrl);
        } else {
          toast(
            t('There was an error getting the login URL. Please refresh the page and try again.'),
            {
              type: ToastType.ERROR,
            },
          );
        }
      } catch (error) {
        toast(t('There was an error creating an integration. Please try again.'), {
          type: ToastType.ERROR,
        });
      }
    }
  }

  function handleClickConfirmMarketplaceSync(): void {
    account.setSetting('confirmed_bbrenxt_marketplace_sync', true);
    setConfirmed(true);
    try {
      account.patchDirty();
    } catch (error) {
      toast(t('There was an error saving your confirmation:\n{{- error}}', { error }), {
        type: ToastType.ERROR,
      });
    }
  }

  return (
    <>
      <div className={styles.header}>
        <h4>{t('2 steps to get started')}</h4>
        <p>{t('Start syncing all your data by completing these tasks.')}</p>
      </div>
      <Card
        key={'add-feathr-application'}
        theme={!confirmed ? 'selected' : 'default'}
        width={'full'}
      >
        <Card.Header
          description={
            <>
              {t("Add the Feathr application to your Raiser's Edge NXT account. ")}
              <Button
                href={
                  'https://app.blackbaud.com/marketplace/applications/1d27e979-36b4-4f8e-be7b-ec2b74ee97de?action=getstarted'
                }
                name={'add-application-link'}
                suffix={<FontAwesomeIcon icon={faArrowUpRightFromSquare} />}
                target={'_blank'}
                type={'link'}
              >
                {t('Application marketplace')}
              </Button>
            </>
          }
          padding={'relaxed'}
          prefix={<Step status={confirmed ? 'finished' : 'processing'} stepNumber={1} />}
          title={t('Add Feathr application')}
        >
          {!confirmed && (
            <Toolbar align={'right'} className={styles.toolbar}>
              <Button onClick={handleClickConfirmMarketplaceSync}>
                {t('Confirm and continue')}
              </Button>
            </Toolbar>
          )}
        </Card.Header>
      </Card>
      <Card key={'connect-blackbaud'} theme={confirmed ? 'selected' : 'disabled'} width={'full'}>
        <Card.Header
          description={t(
            "Use your Blackbaud admin credentials to connect Raiser's Edge to Feathr.",
          )}
          padding={'relaxed'}
          prefix={<Step status={confirmed ? 'processing' : 'waiting'} stepNumber={2} />}
          title={t('Connect Blackbaud account')}
        >
          <Toolbar align={'right'} className={styles.toolbar}>
            <Button
              disabled={!confirmed}
              href={loginUrl}
              isLoading={integration?.isPending}
              onClick={loginUrl ? undefined : handleClickConnect}
            >
              {t('Connect Blackbaud')}
            </Button>
          </Toolbar>
        </Card.Header>
      </Card>
    </>
  );
}

export default observer(RaisersEdgeConnectBlackbaudCard);
