import type { TFunction } from 'i18next';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { ITimeFrameOption, TTimeFrame, TTimeFrameValue } from '@feathr/components';
import type { ENamespaces, ETimeFrameKeys, IPersistProps, TTimeUnit } from '@feathr/hooks';
import { getFromLocalStorage, getPastDate, saveToLocalStorage } from '@feathr/hooks';

interface IUseTimeFrame {
  defaultTimeFrame?: TTimeFrame;
  defaultTimeFrameValueAmount?: number;
  defaultTimeFrameValueUnit?: TTimeUnit;
  persist?: IPersistProps<ETimeFrameKeys, ENamespaces>;
}

interface IUseTimeFrameReturn {
  options: ITimeFrameOption[];
  timeFrame: TTimeFrame;
  timeFrameValue: TTimeFrameValue;
  handleChangeTimeFrame: ({ id, value }: ITimeFrameOption) => void;
}

interface IGetTimeFrameOptions {
  t: TFunction;
  today: string;
}

function getTimeFrameOptions({ t, today }: IGetTimeFrameOptions): ITimeFrameOption[] {
  const options: ITimeFrameOption[] = [
    {
      id: '30d',
      name: t('Last 30 days'),
      value: [getPastDate({ amount: 30, unit: 'days' }), today],
    },
    {
      id: '60d',
      name: t('Last 60 days'),
      value: [getPastDate({ amount: 60, unit: 'days' }), today],
    },
    {
      id: '90d',
      name: t('Last 90 days'),
      value: [getPastDate({ amount: 90, unit: 'days' }), today],
    },
    {
      id: '12m',
      name: t('Last 12 months'),
      value: [getPastDate({ amount: 12, unit: 'months' }), today],
    },
  ];

  return options;
}

export function useTimeFrameSelect({
  defaultTimeFrame = '30d',
  defaultTimeFrameValueAmount = 30,
  defaultTimeFrameValueUnit = 'days',
  persist,
}: IUseTimeFrame = {}): IUseTimeFrameReturn {
  const { t } = useTranslation();
  const today = new Date().toISOString().split('T')[0];
  const options = getTimeFrameOptions({ t, today });

  const defaultTimeFrameValue: TTimeFrameValue = [
    getPastDate({ amount: defaultTimeFrameValueAmount, unit: defaultTimeFrameValueUnit }),
    today,
  ];
  const localStorageTimeFrame = persist
    ? getFromLocalStorage<TTimeFrame>(persist.namespace, persist.key)
    : undefined;
  const [timeFrame, setTimeFrame] = useState<TTimeFrame>(localStorageTimeFrame ?? defaultTimeFrame);

  const timeFrameValue: TTimeFrameValue =
    options.find(({ id }) => id === localStorageTimeFrame)?.value ?? defaultTimeFrameValue;

  const handleChangeTimeFrame = useCallback(({ id }: ITimeFrameOption): void => {
    setTimeFrame(id);
    if (persist) {
      saveToLocalStorage(persist.namespace, persist.key, id);
    }
  }, []);

  return {
    options,
    timeFrame,
    timeFrameValue,
    handleChangeTimeFrame,
  };
}
export default useTimeFrameSelect;
