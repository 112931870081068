import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { User } from '@feathr/blackbox';
import {
  ButtonValid,
  Fieldset,
  Form,
  ImagePicker,
  Input,
  toast,
  Tooltip,
  Value,
} from '@feathr/components';
import Page from '@feathr/extender/App/Page';
import TimezoneSelect from '@feathr/extender/components/TimezoneSelect';
import { useFlags, useUser } from '@feathr/extender/state';
import { flattenError, flattenErrors } from '@feathr/hooks';
import type { Languages } from '@feathr/locales';
import { DefaultLanguage, i18n } from '@feathr/locales';
import type { TValidateGrouped } from '@feathr/rachis';

import LanguageSelect from './LanguageSelect';

interface IErrors extends TValidateGrouped {
  name?: string[];
  picture?: string[];
  calendly_link?: string[];
}

function ProfilePage(): JSX.Element {
  const flags = useFlags();
  const user = useUser();
  const { t } = useTranslation();

  function handleLanguageChange(lng: Languages = 'en-US'): void {
    user?.setSetting('lng', lng);
  }

  async function handleSave(): Promise<void> {
    try {
      const savedUser = (await user.patchDirty()) as User;
      const lng = savedUser.getSetting('lng', DefaultLanguage);
      if (i18n.language !== lng) {
        i18n.changeLanguage(lng);
        document.documentElement.lang = lng;
      }
      toast(t('Your settings were saved.'), { type: 'success' });
    } catch (e) {
      toast(t('There was an error saving your settings.'), { type: 'error' });
    }
  }

  const errors = user.validate<IErrors>(
    ['name', 'picture', 'calendly_link'],
    false,
    'grouped',
  ).errors;

  return (
    <Page title={t('Profile')}>
      <Form
        actions={
          <ButtonValid errors={flattenErrors(errors)} onClick={handleSave}>
            Save
          </ButtonValid>
        }
        description={t('Change your profile information, avatar, etc.')}
        label={t('User profile')}
      >
        <ImagePicker
          attribute={'picture'}
          buttonText={t('Upload profile picture')}
          model={user}
          validationError={flattenError(errors.picture)}
        />
        <Value label={t('Email')} value={user.get('email')} />
        <Input
          attribute={'name'}
          label={t('Name')}
          model={user}
          type={'text'}
          validationError={flattenError(errors.name)}
        />
        {flags.locales && (
          <Fieldset>
            <LanguageSelect
              onChange={handleLanguageChange}
              onClear={handleLanguageChange}
              value={user.getSetting('lng', DefaultLanguage)}
            />
          </Fieldset>
        )}
        <Input
          attribute={'calendly_link'}
          helpText={t('Enter your Calendly or Zoom booking link')}
          label={t('Booking link')}
          model={user}
          placeholder={'https://calendly.com/your-link'}
          type={'url'}
          validationError={flattenError(errors.calendly_link)}
        />
        <Fieldset>
          <Tooltip
            placement={'topLeft'}
            title={t('Used to set the time zone of start and end dates for email campaigns')}
          >
            <TimezoneSelect label={t('Timezone')} value={user.get('timezone', 'UTC')} />
          </Tooltip>
        </Fieldset>
      </Form>
    </Page>
  );
}

export default observer(ProfilePage);
