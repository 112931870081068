import type { WithT } from 'i18next';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import type { JSX } from 'react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { AutoPinpointEmailCampaign, TPinpointSubtype, TUnitOfTime } from '@feathr/blackbox';
import type { IRadioOption } from '@feathr/components';
import {
  Button,
  ButtonValid,
  CardContent,
  CardHeader,
  CardV2 as Card,
  DatePicker,
  Fieldset,
  Form,
  NumberInput,
  Radios,
  Select,
} from '@feathr/components';
import { useUser } from '@feathr/extender/state';
import { flattenError, flattenErrors, TimeFormat } from '@feathr/hooks';
import type { TValidateGrouped } from '@feathr/rachis';

import type { IActionErrors } from '../../../CampaignSummary';
import PinpointTriggersConfig from '../../PinpointActions/PinpointTriggersConfig';

import * as styles from './AutoPinpointEmailCampaignStepAutomation.css';
interface IMenuOption {
  id: TUnitOfTime;
  value: string;
}

interface IProps {
  campaign: AutoPinpointEmailCampaign;
  onNext: () => void;
  onPrev: () => void;
  disabled: boolean;
}

interface IButtonProps extends WithT {
  campaign: AutoPinpointEmailCampaign;
  onNext: () => void;
}

interface IErrors extends TValidateGrouped {
  actions?: IActionErrors[];
  cooldown_value?: string[];
  delay_unit?: string[];
  delay_value?: string[];
  repeat?: string[];
  send_schedule?: string[];
}

const minimumTimeValues = { minutes: 5, hours: 1, days: 1, weeks: 1 };

export function validateStepAutomation(campaign: AutoPinpointEmailCampaign): IErrors {
  // TODO: Fix pre-existing bug on validation on cooldown_value and delay_value fields - see #2767
  return campaign.validate<IErrors>(
    [
      'actions',
      'cooldown_value',
      'cooldown_unit',
      'delay_unit',
      'delay_value',
      'repeat',
      'time_to_send',
    ],
    false,
    'grouped',
  ).errors;
}

const NextStepButton = observer(({ campaign, onNext, t }: IButtonProps): JSX.Element => {
  const validationErrors = validateStepAutomation(campaign);

  return (
    <ButtonValid errors={flattenErrors(validationErrors)} name={'next_step'} onClick={onNext}>
      {t('Next')}
    </ButtonValid>
  );
});

function AutoPinpointEmailCampaignStepAutomation({
  campaign,
  disabled,
  onNext,
  onPrev,
}: IProps): JSX.Element {
  const { t } = useTranslation();
  const user = useUser();

  const initialDelay =
    campaign.get('delay_value') === 5 && campaign.get('delay_unit') === 'seconds'
      ? 'immediately'
      : 'delay';
  const initialSubtype = campaign.get('subtype', 'activity');

  const [delay, setDelay] = useState<'immediately' | 'delay'>(initialDelay);
  const [subtype, setSubtype] = useState<TPinpointSubtype>(initialSubtype);
  const [repeat, setRepeat] = useState<'first' | 'every'>(
    campaign.get('repeat', false) ? 'every' : 'first',
  );

  const userTimezone = user.get('timezone');
  const defaults = campaign.getDefaults();
  const cooldownUnit = campaign.get('cooldown_unit', 'minutes');
  const timeOfDay = campaign.get('time_to_send');
  const isTimeSubtype = subtype === 'time';

  function onDelayChange(newValue?: string): void {
    // When sending immediately, the delay is actually 5 seconds.
    if (newValue === 'immediately') {
      campaign.set({
        delay_value: 5,
        delay: newValue,
        delay_unit: 'seconds',
        send_schedule: 'now',
      });
      setDelay(newValue);
    }

    // If delay is selected, set send schedule fields back to default values
    if (newValue === 'delay') {
      campaign.set({
        send_schedule: defaults.send_schedule,
        delay: newValue,
        delay_value: defaults.delay_value,
        delay_unit: defaults.delay_unit,
      });
      setDelay(newValue);
    }
  }

  function handleSubtypeChange(newValue?: string): void {
    if (!newValue) {
      return;
    }

    if (newValue === 'time') {
      setRepeat('first');
      /*
       * Even though the backend will ignore delay_value and cooldown_value when subtype is time,
       * reset them to zero so the data is more representative of the UI state.
       */
      campaign.set({
        subtype: newValue,
        repeat: false,
        delay_value: 0,
        cooldown_value: 0,
        mode: 'match_any',
      });
    } else {
      campaign.set({
        delay_value: defaults.delay_value,
        delay_unit: defaults.delay_unit,
        mode: defaults.mode,
        send_schedule: defaults.send_schedule,
        subtype: newValue as TPinpointSubtype,
        time_to_send: undefined,
      });
    }
    setSubtype(newValue as TPinpointSubtype);

    // Clear the existing trigger if trigger type is modified
    campaign.set({ actions: [] });
  }

  function handleChangeTimeOfDay(newTimestamp?: string): void {
    const newMoment = moment.utc(newTimestamp);
    campaign.set({ time_to_send: newMoment.format(TimeFormat.isoDateTime) });
  }

  function getOptionValue(option: IMenuOption): string {
    return option.value;
  }

  function getOptionLabelDelay(option: IMenuOption): string | undefined {
    const delayValue = campaign.get('delay_value');
    return {
      minutes: t('minute', { count: delayValue }),
      hours: t('hour', { count: delayValue }),
      days: t('day', { count: delayValue }),
      weeks: t('week', { count: delayValue }),
    }[option.id];
  }

  function getOptionLabelCooldown(option: IMenuOption): string | undefined {
    const cooldownValue = campaign.get('cooldown_value');
    return {
      minutes: t('minute', { count: cooldownValue }),
      hours: t('hour', { count: cooldownValue }),
      days: t('day', { count: cooldownValue }),
      weeks: t('week', { count: cooldownValue }),
    }[option.id];
  }

  function onSelectSingle(option: IMenuOption): void {
    campaign.set({ delay_unit: option.id, delay_value: minimumTimeValues[option.id] });
  }

  function handleChangeRepeat(newValue?: string): void {
    if (newValue) {
      campaign.set({
        repeat: newValue === 'every',
        // Reset the cooldown value to the selected cooldown unit's minimum value
        cooldown_value: newValue === 'every' ? minimumTimeValues[cooldownUnit] : 0,
      });

      setRepeat(newValue as 'first' | 'every');
    }
  }

  function getTimeOptions(): IMenuOption[] {
    return [
      { id: 'minutes', value: 'minutes' },
      { id: 'hours', value: 'hours' },
      { id: 'days', value: 'days' },
      { id: 'weeks', value: 'weeks' },
    ];
  }

  function getTimeValue(attribute: 'delay_unit' | 'cooldown_unit'): IMenuOption | undefined {
    return getTimeOptions().find(
      (option) =>
        option.id === campaign.get(attribute, attribute.startsWith('delay') ? 'minutes' : 'hours'),
    );
  }

  function getMinimumTimeValue(timeUnitAttribute: 'delay_unit' | 'cooldown_unit'): number {
    const timeUnit = campaign.get(
      timeUnitAttribute,
      timeUnitAttribute.startsWith('delay') ? 'minutes' : 'hours',
    );
    return minimumTimeValues[timeUnit];
  }

  function handleChangeCooldownUnit(option: IMenuOption): void {
    campaign.set({ cooldown_unit: option.id, cooldown_value: minimumTimeValues[option.id] });
  }

  const validationErrors = validateStepAutomation(campaign);

  const triggerTypeOptions: Array<IRadioOption<TPinpointSubtype>> = [
    {
      id: 'activity',
      name: t('Activity happened'),
    },
    {
      id: 'update',
      name: t('Field changed'),
    },
    {
      id: 'time',
      name: t('Date triggered'),
    },
  ];

  return (
    <Form
      actions={[
        <Button key={'prev'} name={'previous_step'} onClick={onPrev}>
          {t('Previous')}
        </Button>,
        <NextStepButton campaign={campaign} key={'next'} onNext={onNext} t={t} />,
      ]}
      description={t(
        'When should the email in this campaign be sent to people? Configure as many triggers as you like. Activities triggers (like URL visits) can only be combined with other activities. Attribute triggers (like job title) can only be combined with other attributes.',
      )}
      label={t('Edit Campaign: Targeting')}
      title={t('Automation')}
      width={'wide'}
    >
      <Card width={'full'}>
        <Card.Header title={t('Auto Send Type')} />
        <Card.Content>
          <Fieldset>
            <Radios
              dataName={'subtype'}
              disabled={disabled}
              helpText={t('Choose the type of trigger you want to use for this email.')}
              label={t('Trigger type')}
              layout={'block'}
              onChange={handleSubtypeChange}
              options={triggerTypeOptions}
              required={true}
              value={subtype}
            />
          </Fieldset>
        </Card.Content>
      </Card>

      <PinpointTriggersConfig campaign={campaign} disabled={disabled} />

      <Card width={'full'}>
        <CardHeader title={t('Schedule')} />
        <CardContent>
          <Fieldset>
            {!isTimeSubtype && (
              <Radios
                dataName={'send_schedule'}
                disabled={disabled}
                helpText={t('Send your email immediately following the trigger, or after a delay.')}
                label={t('Send schedule')}
                layout={'block'}
                onChange={onDelayChange}
                options={[
                  { id: 'immediately', name: t('Immediately') },
                  { id: 'delay', name: t('Delay') },
                ]}
                required={true}
                value={delay}
              />
            )}
            {delay === 'delay' && !isTimeSubtype && (
              <NumberInput
                attribute={'delay_value'}
                className={styles.delay}
                clearableClassName={styles.delayElement}
                disabled={disabled}
                helpText={t(
                  'The amount of time between the person performing the action and sending the email.',
                )}
                label={t('Delay time')}
                min={1}
                model={campaign}
                name={'delay_value'}
                suffix={
                  <Select
                    disabled={disabled}
                    getOptionLabel={getOptionLabelDelay}
                    getOptionValue={getOptionValue}
                    name={'delay_unit'}
                    onSelectSingle={onSelectSingle}
                    options={getTimeOptions()}
                    value={getTimeValue('delay_unit')}
                  />
                }
                validationError={flattenError(validationErrors.delay_value)}
              />
            )}

            {isTimeSubtype && (
              <DatePicker
                autoComplete={'off'}
                dateFormat={'h:mm aa'}
                disabled={disabled}
                helpText={t(
                  'On the day that the date-trigger matches, choose when the email should be sent.',
                )}
                label={t('Time of day')}
                model={campaign}
                name={'time_to_send'}
                onDateStrChange={handleChangeTimeOfDay}
                showTimeSelect={true}
                showTimeSelectOnly={true}
                timeIntervals={5}
                timezone={userTimezone}
                validationError={flattenError(validationErrors.time_to_send)}
                value={timeOfDay}
                wrapperClassName={styles.timeOfDaySelect}
              />
            )}
            <Radios
              disabled={disabled}
              helpText={t(
                'Send your email only the first time the person performs the trigger, or every time.',
              )}
              label={t('Send cadence')}
              layout={'block'}
              name={'send_cadence'}
              onChange={handleChangeRepeat}
              options={[
                { id: 'first', name: t('Send only the first time') },
                { id: 'every', name: t('Send every time'), disabled: isTimeSubtype },
              ]}
              required={true}
              value={repeat}
            />
            {repeat === 'every' && (
              <NumberInput
                attribute={'cooldown_value'}
                className={styles.delay}
                clearableClassName={styles.delayElement}
                disabled={disabled}
                helpText={t(
                  'The minimum amount of time that should pass before someone is able to trigger the campaign again.',
                )}
                label={t('Cooldown period')}
                min={getMinimumTimeValue('cooldown_unit')}
                model={campaign}
                name={'cooldown_value'}
                suffix={
                  <Select
                    disabled={disabled}
                    getOptionLabel={getOptionLabelCooldown}
                    getOptionValue={getOptionValue}
                    name={'cooldown_unit'}
                    onSelectSingle={handleChangeCooldownUnit}
                    options={getTimeOptions()}
                    value={getTimeValue('cooldown_unit')}
                  />
                }
                validationError={flattenError(validationErrors.cooldown_value)}
              />
            )}
          </Fieldset>
        </CardContent>
      </Card>
    </Form>
  );
}

export default observer(AutoPinpointEmailCampaignStepAutomation);
