import type { JSX } from 'react';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { ToastType } from 'react-toastify';

import type { Segment as Group } from '@feathr/blackbox';
import type { TModalActionEvent } from '@feathr/components';
import { Input, Modal, toast } from '@feathr/components';
import { StoresContext, useLocalUrl } from '@feathr/extender/state';

interface IProps {
  group: Group;
  onClose: (event: TModalActionEvent) => void;
}

function SaveAsNewGroupModal({ group: { id }, group, onClose }: Readonly<IProps>): JSX.Element {
  const { t } = useTranslation();
  const { Segments: Groups } = useContext(StoresContext);
  const [groupName, setGroupName] = useState<string>('');
  const history = useHistory();
  const localUrl = useLocalUrl();
  const canEdit = group.permissions.canEdit;

  async function handleSaveGroup(): Promise<void> {
    const cloneParams = canEdit
      ? {
          name: groupName,
          predicates: group.get('predicates'),
          lookback_mode: group.get('lookback_mode'),
          lookback_value: group.get('lookback_value'),
          lookback_units: group.get('lookback_units'),
          mode: group.get('mode'),
        }
      : { name: groupName };
    try {
      const response = await Groups.clone(id, cloneParams);
      toast(t('Group saved as {{-name}}.', { name: response.name }), {
        type: ToastType.SUCCESS,
      });
      Groups.refreshApiCache();
      history.push(localUrl(response.getItemUrl()));
    } catch (error) {
      toast(t('There was an error saving the group.\n{{- error}}', { error }), {
        type: ToastType.ERROR,
      });
    }
  }

  function handleSetGroupName(newValue?: string): void {
    setGroupName(newValue ?? '');
  }

  return (
    <Modal
      confirmButtonText={t('Confirm')}
      confirmDisabled={groupName.length === 0}
      controlled={true}
      description={
        !canEdit &&
        t(
          'Any future changes made by the admin to the original group will be automatically reflected in any new groups.',
        )
      }
      onClose={onClose}
      onConfirm={handleSaveGroup}
      size={'sm'}
      t={t}
      title={t('Save as New Group')}
    >
      <Input
        label={t('Group name')}
        onChange={handleSetGroupName}
        placeholder={t('Enter group name')}
        value={groupName}
      />
    </Modal>
  );
}

export default SaveAsNewGroupModal;
