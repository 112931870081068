import { makeObservable } from 'mobx';

import { simpleHash } from '@feathr/hooks';
import type { Attributes, IBaseAttributes, ICollectionStore, IGetParams } from '@feathr/rachis';
import { isWretchError } from '@feathr/rachis';
import { Collection, Model, wretch } from '@feathr/rachis';

import type { IFlavors } from './stats';

export interface IAggregatedConversionAttributionModelStats {
  num: number;
  roi: number;
}

export interface IAggregatedConversionStats {
  first_touch: IAggregatedConversionAttributionModelStats;
  full: IAggregatedConversionAttributionModelStats;
  last_touch: IAggregatedConversionAttributionModelStats;
  linear: IAggregatedConversionAttributionModelStats;
  positional: IAggregatedConversionAttributionModelStats;
  temporal: IAggregatedConversionAttributionModelStats;
}

export interface IKeywordStats {
  num_clicks: number;
  num_views: number;
  spend: number;
  term: string;
}

export interface ITopGoal {
  goal_id: string;
  count: number;
}

export interface IAggregatedStats extends IBaseAttributes {
  conversions: IAggregatedConversionStats;
  flavors: IFlavors;
  num_clicks: number;
  num_clicks_new: number;
  num_new_users_new: number;
  num_users: number; // Missing
  num_users_active: number; // Missing
  num_users_new: number;
  num_targeted: number;
  num_views: number;
  num_not_sent: number;
  num_new_users_new_reachable: number;
  spend: number;
  terms?: IKeywordStats[];

  // Account stats
  ad_click_through_rate?: number;
  email_click_through_rate?: number;
  num_ad_clicks?: number;
  num_ad_views?: number;
  num_persons_created?: number;
  num_persons_created_by_data_integration?: number;
  num_persons_created_by_expansion?: number;
  num_persons_created_by_import?: number;
  num_persons_created_by_pixel?: number;
  num_persons_reached?: number;
  num_persons_with_ad_clicks?: number;
  num_persons_with_ad_views?: number;
  num_persons_with_page_link_clicks?: number;
  num_persons_with_page_views?: number;
  num_persons_with_email_clicks?: number;
  num_persons_with_email_opens?: number;
  num_email_bounces_and_complaints?: number;
  num_email_clicks?: number;
  num_email_opens?: number;
  num_email_sends?: number;

  // Goals
  /** The top three goals within a given date range. */
  top_goals?: ITopGoal[];
}

export class AggregatedStat extends Model<IAggregatedStats> {
  public readonly className = 'DailyStat';

  public constraints = {};
}

export type TAggregatedStatsModel =
  | 'account'
  | 'campaign'
  | 'creative'
  | 'event'
  | 'facebook_campaign'
  | 'facebook_creative'
  | 'flight'
  | 'segment';

interface IStatsGetParams extends IGetParams<IAggregatedStats> {
  model: TAggregatedStatsModel;
}

export class AggregatedStats extends Collection<AggregatedStat> {
  constructor(
    initialModels: Array<Partial<Attributes<AggregatedStat>>>,
    store: ICollectionStore = {},
  ) {
    super(initialModels, store);

    makeObservable(this);
  }

  public getModel(attributes: Partial<IAggregatedStats>): AggregatedStat {
    return new AggregatedStat(attributes);
  }

  public getClassName(): string {
    return 'stats_aggregated';
  }

  public url(): string {
    return `${this.getHostname()}stats/aggregated`;
  }

  public aggregate(
    params: IStatsGetParams,
    callback?: (model: AggregatedStat) => void,
  ): AggregatedStat {
    // Hash params; be sure to exclude the URL and question mark
    const id = simpleHash(this.paramsToUrl(params).replace(this.url(), '').replace(/^\?/, ''));
    return super.get(id, {}, params, {}, callback);
  }

  public async fetchModel(
    model: AggregatedStat,
    params: IGetParams<IAggregatedStats> = {},
    fetchOptions: Partial<RequestInit> = {},
    callback?: (model: AggregatedStat) => void,
  ): Promise<AggregatedStat> {
    const url = this.paramsToUrl(params);
    model.isPending = true;
    const response = await wretch<IAggregatedStats>(url, {
      method: 'GET',
      headers: this.getHeaders(),
      ...fetchOptions,
    });
    if (isWretchError(response)) {
      this.processErrorResponse(model, response.error);
      return model;
    }
    const out = this.processJSONResponse({ ...response.data }, params.only);
    if (callback) {
      callback(out);
    }
    return out;
  }
}
